import React, { Component } from 'react';
import styled from 'styled-components';
import bgImage from '../assets/LandingScreenBG.jpg';
import Header from '../components/Header';
import Login from "../components/Login";
import Layout from "../components/layout";

class LoginContent extends Component { 
  componentDidMount() {    
    document.body.classList.add("background-blue");
  }

  componentWillUnmount() {
      document.body.classList.remove("background-blue");
  }
  render() {         
    return (  
      <Layout className="page-wrapper">
      <Header />       
      <PageWrapper id="wrapper">             
        <div className="content-wrapper">         
          <IntroContent>
          <IntroTitle>SHOWCASING OUR
              <span>MARKETING SOLUTIONS</span>
          </IntroTitle>
          <IntroText>Join us on a journey demonstrating how we can help you build your business with flexible marketing solutions</IntroText>                
          <Login />        
      </IntroContent>
      </div>
      </PageWrapper>
      </Layout>
    );
  }
}

export default LoginContent

const PageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 7rem 1.8rem 1.8rem 1.8rem;  
  background: #e1e6e9 url(${bgImage}) no-repeat 0 0;
  background-size: cover;
  .content-wrapper {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  @media (min-width: 1600px) {
    .content-wrapper {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
  @media (max-width: 991px) {
    position: relative;    
    margin: -7rem 0 0 0;
    background-size: cover;
    background-position: 50% 50%;
  }
  @media (max-width: 767px) {
    margin: -5rem 0 0 0;
    padding: 5rem 1.2rem 1.2rem 1.2rem;    
  }
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) {
    margin: -6rem 0 0 0; 
    padding: 6rem 1.2rem 1.2rem 1.2rem; 
  }
  @media (max-width: 765px)
  and (min-width: 480px) {
    margin: -6.6rem 0 0 0;
    padding: 6.6rem 1.2rem 1.2rem 1.2rem;
  }
`
const IntroContent = styled.div`
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 3rem;      
    @media (min-width: 1600px) {
        padding-bottom: 5rem;
    }

    @media (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;
        background: rgba(255,255,255,0.6);
        padding: 1.5rem;       
    }
    @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;       
        width: 100%;
        padding: 1rem;
    }
`;

const IntroTitle = styled.h1`
    font-size: 45px;
    line-height: 1.1;
    text-transform: uppercase;
    color: #333F48;
    font-weight: 700;
    span {
        display: block;
        color: #FF8674;
    }
    @media (max-width: 767px) {
        font-size: 32px;
    }
`
const IntroText = styled.p`
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
`