import React from "react";
import styled from 'styled-components';
import { navigate } from "gatsby";

const SignIn = ({ handleSubmit, handleUpdate }) => {
  return (
    <Form   
      method="post"
      onSubmit={event => {
        handleSubmit(event)
        navigate(`/`)
      }}
    >
      <IntroText>Log in to see restricted content</IntroText>
      <FormCol>
        <label htmlFor="username">Username</label>
        <input
          className="form__input"
          type="text"
          name="username"
          id="username"
          onChange={handleUpdate}
        />
      </FormCol>
      <FormCol>
        <label htmlFor="password">Password</label>
        <input
          className="form__input"
          type="password"
          name="password"
          id="password"
          onChange={handleUpdate}
        />    
      </FormCol>
      <FormCol className="text-right">
        <input className="form__button" type="submit" value="Log In" />
      </FormCol>
    </Form>
  )
}
export default SignIn;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end; 
  background: #ffffff;
  width: 100%;
  padding: 15px; 
  @media (max-width: 767px) {
    background: transparent none;
  }
`;

const FormCol = styled.div`
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
    &.text-right {
        text-align: right; 
        margin-left: auto;     
    }    
    label {
        display: block;
        color: #000000;
        padding-bottom: 4px;
    }
    .form__input {
        border: 0;
        background: #e1e6e9;
        padding: 10px;
        width: 100%;
        font-size: 14px;
    }

    .form__button {
        border: 0;
        display: inline-block;
        width: auto;
        text-transform: uppercase;
        text-decoration: none;        
        margin-top: 15px;
        font-size: 16px;
        padding: 10px 20px;
        font-weight: 600;
        color: #ffffff;
        background: #FF8674;
        transition: all .2s ease-in;        
        &:hover {
            background: #768692;
        }
    }
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%; 
      padding: 0;
    }

`;

const IntroText = styled.p`
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      padding: 0;
    }
`;
